<template>
  <div id="liveData">
    <!-- 设备统计 -->
    <div v-show="!isDeviceInfo">
      <ChartSum :data="deviceCountArr" @itemFilter="divicesFilter"></ChartSum>
    </div>
    <!-- 设备数据——所有设备 -->
    <div class="c-container-box" v-show="!isDeviceInfo">
      <el-tabs v-model="activeName" v-if="models != undefined">
        <el-tab-pane label="表格视图" name="table">
          <template v-for="(model,index) in models">
            <!--通用设备-->
            <template v-if="model.modelName!='dmWater' && model.modelName!='dmMaster'">
              <el-table style="margin-bottom: 20px;" :key="index" :data="tableDataFilter(model.modelName)" :span-method="(...args) => objectSpanMethod(...args, model.modelName)">
                <el-table-column label="设备名称" fixed width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span class="c-clickable-cell" @click="viewDeviceInfo(scope.row)">{{scope.row.title==''?scope.row.id:scope.row.title}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="设备UID" fixed width="180" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span class="c-clickable-cell" @click="viewDeviceInfo(scope.row)">{{scope.row.uid}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="设备状态" width="120">
                  <template slot-scope="scope">
                    <!--设备状态-->
                    <div class="device_status">
                      <div class="iconfont" :class="scope.row.online=='1'? 'icon_online': scope.row.online=='0'?'icon_offline':'icon_sleep'"></div>
                      <el-tooltip :disabled="scope.row.online=='2'?false:true" :content="scope.row.wakeupTime" placement="bottom" effect="light">
                        <div class="title"> {{scope.row.online=='1'?'在线':scope.row.online=='0'?'离线':'休眠'}}</div>
                      </el-tooltip>
                    </div>
                    <!--充电-->
                    <div class="device_status" v-if="(scope.row.status != undefined && scope.row.status.battery)&& scope.row.online!='0'">
                      <div class="iconfont" :class="scope.row.status.charge=='no'?setBatteryico(scope.row.status.battery):'icon_battery_charging'" style="font-size:22px;"></div>
                      <div class="title">{{scope.row.status.battery}}%</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="time" label="最近更新时间" width="200">
                  <template slot-scope="scope">
                    {{scope.row.lastAlive}}
                  </template>
                </el-table-column>
                <el-table-column label="设备数据">
                  <template v-for="(item,index) in model.modelValue.fields">
                    <el-table-column v-if="!item.hide" :key="index">
                      <template slot="header">
                        <span>{{item.title}}</span>
                      </template>
                      <template slot-scope="scope">
                        {{filterValue(scope.row.datas,index)}}
                      </template>
                    </el-table-column>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="100" fixed="right">
                  <template slot-scope="scope">
                    <!-- <el-tooltip class="item" content="设备详情" placement="bottom"> -->
                    <el-button type="primary" icon="el-icon-document" circle size="mini" @click="viewDeviceInfo(scope.row)"></el-button>
                    <!-- </el-tooltip> -->
                  </template>
                </el-table-column>
              </el-table>
            </template>
            <!--地埋设备-->
            <template v-else>
              <el-table style="margin-bottom: 20px;" :key="index" :data="tableDataFilter(model.modelName)">
                <el-table-column label="设备名称" fixed width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span class="c-clickable-cell" @click="viewDeviceInfo(scope.row)">{{scope.row.title==''?scope.row.id:scope.row.title}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="设备UID" width="180" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span class="c-clickable-cell" @click="viewDeviceInfo(scope.row)">{{scope.row.uid}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="设备状态" width="100">
                  <template slot-scope="scope" style="padding:-14px">
                    <!--设备状态-->
                    <div class="device_status">
                      <div class="iconfont" :class="scope.row.online=='1'? 'icon_online': scope.row.online=='0'?'icon_offline':'icon_sleep'"></div>
                      <el-tooltip :disabled="scope.row.online=='2'?false:true" :content="scope.row.wakeupTime" placement="bottom" effect="light">
                        <div class="title"> {{scope.row.online=='1'?'在线':scope.row.online=='0'?'离线':'休眠'}}</div>
                      </el-tooltip>
                    </div>
                    <!--充电-->
                    <div class="device_status" v-if="(scope.row.status != undefined && scope.row.status.battery)&& scope.row.online!='0'">
                      <div class="iconfont" :class="scope.row.status.charge=='no'?setBatteryico(scope.row.status.battery):'icon_battery_charging'" style="font-size:22px;"></div>
                      <div class="title">{{scope.row.status.battery}}%</div>
                    </div>

                  </template>
                </el-table-column>
                <el-table-column prop="time" label="最近更新时间" width="200">
                  <template slot-scope="scope">
                    {{scope.row.lastAlive}}
                  </template>
                </el-table-column>
                <el-table-column label="设备数据">
                  <template>
                    <el-table-column>
                      <template slot="header">
                        <span>节点</span><span style="font-size:10px">(米)</span>
                      </template>
                      <template slot-scope="scope">
                        <!-- {{filterValue(scope.row.datas,index)}}{{filterNode("rainFall",item).name}} -->
                        <template>
                          <el-row :gutter="24">
                            <el-col v-for="(item,index) in scope.row.datas" :key="index" :span="6">
                              <el-tag type="info" size="small" style="border-radius: 30px;">{{filterNode("i",item).value}}</el-tag>
                              <span style="font-size:16px;padding:5px 5px;vertical-align:middle;">{{filterNode("f",item).value}}</span>
                              <template v-if="filterNode('rainFall',item) != void 0">
                                <el-tag type="info" size="small" style="border-radius: 30px;">
                                  <!-- <i class="m-iconfont" :class="'icon-' + filterNode('rainFall',item).key" style="vertical-align:middle;font-size:12px;"></i> -->
                                  <span style="line-height:10px ;vertical-align:middle">雨量</span>
                                </el-tag>
                                <span style="font-size:16px;padding:5px 5px;vertical-align:middle;">{{filterNode("rainFall",item).value}}</span>
                                <span style="zoom: 0.8;padding:5px 0px;vertical-align:middle;">{{filterNode("rainFall",item).unit}}</span>
                              </template>

                            </el-col>
                          </el-row>
                        </template>
                      </template>
                    </el-table-column>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="100" fixed="right">
                  <template slot-scope="scope">
                    <!-- <el-tooltip class="item" content="设备详情" placement="bottom"> -->
                    <el-button type="primary" icon="el-icon-document" circle size="mini" @click="viewDeviceInfo(scope.row)"></el-button>
                    <!-- </el-tooltip> -->
                  </template>
                </el-table-column>
              </el-table>

            </template>
          </template>

        </el-tab-pane>
        <!--对地埋设备单独处理-->
        <!-- <el-tab-pane v-else label="表格视图" name="table">
          <template v-for="(model,index) in models">

          </template>
        </el-tab-pane> -->
        <el-tab-pane label="卡片视图" name="card">
          <CardView v-if="activeName == 'card'" :deviceDataFilter="deviceDataFilter" @viewDeviceInfo="viewDeviceInfo(item)"></CardView>
        </el-tab-pane>
      </el-tabs>
      <el-pagination background :page-sizes="page.sizes" :page-size="page.size" :current-page="page.curPage" layout="total, sizes, prev, pager, next" :total="deviceData.length" v-show="deviceData.length>0" @size-change="sizeChange" @current-change="pageChange">
      </el-pagination>
    </div>
    <!-- 设备数据——单个设备 -->
    <DeviceInfo ref="deviceInfo" v-show="isDeviceInfo" :data="deviceInfo"></DeviceInfo>
  </div>
</template>

<script>
import ChartSum from '../../components/ChartSum.vue';
import DeviceInfo from '../../components/DeviceInfo.vue';

const CardView = () => import('../../components/DataView/CardView');

export default {
  data() {
    const sizes = new Array();
    for (let i = 10; i <= 30; i += 5) {
      sizes.push(i);
    }
    return {
      profile: {},
      models: [],
      deviceData: [],
      activeName: 'table', //表格视图/卡片视图
      sensorArr: [], //表头传感器数据
      dataArr: [], //设备列表数据
      deviceCountArr: [], //设备统计
      isDeviceInfo: false, //设备列表页/详情页切换
      deviceInfo: {},
      // page
      page: {
        sizes: sizes,
        size: 10,
        curPage: 1
      },
      deviceFilterData: [],//筛选后的设备
      dwData: []
    }
  },
  components: {
    ChartSum,
    DeviceInfo,
    CardView
  },
  computed: {
    // 表格单元格数据计算
    filterValue() {

      return (datas, field) => {
        let value = "";
        if (datas != undefined) {
          for (let item of datas) {
            if (item.key == field) {
              value = item.value;
              break;
            }
          }
        }

        return value;
      }
    },
    // 计算当前分页数据
    deviceDataFilter() {
      let page = this.page;
      let arr = this.deviceData.filter((item, index) => index >= ((page.curPage - 1) * page.size) && index <= ((
        page.curPage * page.size) - 1));
      return arr;
    },
    tableDataFilter() {
      return (modelName) => {
        let page = this.page;
        let arr = this.deviceData.filter((item, index) => index >= ((page.curPage - 1) * page.size) && index <= ((page.curPage * page.size) - 1)).filter(data => data.model == modelName);
        // console.log(1111, arr, modelName);
        if (modelName == 'swLora' || modelName == 'dmWater' || modelName == 'dmMaster') {
          // let newArr = new Array()

          // if (arr[0].datas.length != 0) {
          //   arr[0].datas.forEach(e => {
          //     newArr.push({
          //       ...arr[0],
          //       datas: e
          //     })
          //   })
          // } else {
          //   newArr.push({
          //     ...arr[0],
          //     datas: ""
          //   })
          // }
          // console.log("newarr:", arr);
          return arr;
        } else {
          // console.log("arr:", arr);
          return arr;
        }
      }
    },


  },
  watch: {
    "$store.state.profile": {
      handler: function () {
        let profile = this.$store.state.profile;
        this.profile = profile;
      }
    },
    // 监听节点数据切换
    "$store.getters.getCurDevices": {
      handler: function (newVal, oldVal) {
        // 设备数据
        let deviceData = this.$store.getters.getCurDevices;
        this.deviceData = deviceData;
        this.deviceFilterData = deviceData;
        // 模块数据
        let models = this.getModels();
        this.models = models;
        // console.log("models_store:", this.models);

        console.log("models:", this.models);

        this.setHotDevices(); // 设置热点设备
        this.page.curPage = 1; // 重置分页
      }
    },
    // 监听设备数据
    deviceData: {
      handler(newVal) {
        // 只有一台设备
        if (newVal && newVal.length == 1) {
          this.viewDeviceInfo(newVal[0]);
        } else {
          this.isDeviceInfo = false;
        }
      }
    },

  },
  filters: {
    nodeNameFilters: function (val, i) {
      if (!val) {
        return '节点'
      }
      let item = val.filter(e => e.key == 'n')
      let num = val.filter(e => e.key == 'i')
      return item[0].name + num[0].value
    }
  },
  methods: {
    objectSpanMethod({ row, column, rowIndex, columnIndex }, modelName) {
      // debugger
      // console.log("row:", row.title);
      if ((modelName == 'swLora' || modelName == 'dmWater' || modelName == 'dmMaster') && columnIndex < 4) {
        if (rowIndex === 0) {
          // let length = this.tableDataFilter(modelName).length;
          let length = row.datas.length;//判断节点有多少个，就有多少行，最少一行
          if (length == 0) {
            length = 1
          }
          return {
            rowspan: length,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    // 统计设备数量
    setDeviceCount() {
      let countObj = this.$store.getters.getDeviceCount;
      for (let item of this.deviceCountArr) {
        switch (item.id) {
          case 'count':
            item.value = countObj.count;
            break;
          case 'online':
            item.value = countObj.online;
            break;
          case 'offline':
            item.value = countObj.offline;
            break;
          case 'alarm':
            item.value = countObj.alarm;
            break;
          default:
            break;
        }
      }
    },
    //筛选地埋设备字段
    filterNode(type, items) {
      let data = items.filter((item) => {
        return type == item.key
      })
      return data[0]
    },
    // 设置热点设备
    setHotDevices() {
      let uids = "";
      this.deviceDataFilter.forEach((item) => {
        uids += item.id + ",";
      })
      let json = {
        "cmd": "setHotDevices",
        "uids": uids
      }
      this.ws.send(json);
      let models = this.getModels();
      this.models = models;
    },

    // 查看设备详情
    viewDeviceInfo(row) {
      this.$parent.showBack();
      this.isDeviceInfo = true;
      this.deviceInfo = row;
      setTimeout(() => {
        this.$refs.deviceInfo.setChartData();
      }, 100);
    },
    setBatteryico(battery, charge) {
      // console.log("charge", charge);

      if (battery >= 80) {
        return 'iconfont icon_battery_5'
      } else if (battery >= 60) {
        return 'iconfont icon_battery_4'
      }
      else if (battery >= 40) {
        return 'iconfont icon_battery_3'
      }
      else if (battery >= 20) {
        return 'iconfont icon_battery_2'
      } else {
        return 'iconfont icon_battery_1'
      }


    },
    // 分配设备模块
    getModels() {
      if (this.profile.profile == undefined) {
        return;
      }
      let modelsFilter = new Array();
      let models = new Array();
      for (let item of this.deviceDataFilter) {
        if (modelsFilter.indexOf(item.model) == -1) {
          let value = {};
          if (item.model != "") {
            value = this.profile.profile.models[item.model];
            // continue;
          }
          modelsFilter.push(item.model);
          models.push({
            modelName: item.model,
            modelValue: value
          });
        }
      }
      // console.log("models_getModels:", models);
      console.log(2222, '模块分配', models);
      return models;
    },
    getDevice(uid) {
      let obj = this.common.cFindObj(this.deviceData, "id", uid);
      return obj;
    },
    //点击切换不同设备状态的列表
    divicesFilter(item) {
      if (item) {
        this.deviceData = []
        let items = item.split(",")
        this.deviceFilterData.forEach((e) => {
          items.forEach(itemnum => {
            if (itemnum == e.online) {
              this.deviceData.push(e)
            }
          })
        })

      } else {
        this.deviceData = this.deviceFilterData
      }
      //筛选后需要定位到第一页
      this.page.curPage = 1
      //查询获取设备models
      let models = this.getModels();
      this.models = models;
      // console.log("deviceData:", this.deviceData);
    },
    // 分页——当前页改变
    sizeChange(size) {
      this.page.size = size;
      this.page.curPage = 1;
      this.setHotDevices();
    },
    pageChange(curPage) {
      this.page.curPage = curPage;
      this.setHotDevices();
    },

    // 返回
    onBack() {
      this.isDeviceInfo = false;
    }
  },
  created() {
    let models = this.getModels();
    this.models = models;
  },
  mounted() {
    // this.sensorArr = this.common.cGetSensorData();
    // this.dataArr = this.common.cGetDeviceData();
    // 设备总数
    let ids = ['count', 'online', 'offline', 'alarm'];
    let titles = ['设备总数（当前区域下）', '在线设备', '离线设备', '报警设备'];
    let colors = ['#AB7DF6', '#20B2AA', '#4A90E2', '#ff5050'];
    let nums = ["", "1,2", "0", "3"]
    let countArr = new Array();
    for (let i = 0; i < colors.length; i++) {
      countArr.push({
        id: ids[i],
        color: colors[i],
        title: titles[i],
        num: nums[i],
        value: "0"
      })
    }
    this.deviceCountArr = countArr;
    this.setDeviceCount();

    // 所有模块
    let profile = this.$store.state.profile;
    this.profile = profile;
    // 设备数据
    let deviceData = this.$store.getters.getCurDevices;
    this.deviceData = deviceData;
    this.deviceFilterData = deviceData;
    // 模块分配
    let models = this.getModels();
    this.models = models;
    // console.log("models_mounted:", this.models);
    this.setHotDevices(); // 设置热点设备
    //设备上线
    this.ws.addCallback('deviceOnline', (res) => {
      let uidData = this.getDevice(res.uid);
      let isSleep = res.state
      if (isSleep == 'sleep') {  //设备处于休眠状态
        uidData.online = '2';
        uidData.wakeupTime = "唤醒时间:" + res.wakeupTime //唤醒时间
      } else if (uidData != null) {  //唤醒
        uidData.online = '1';
        uidData.wakeupTime = ''
      }
      this.setDeviceCount();
    })
    //设备下线
    this.ws.addCallback('deviceOffline', (res) => {
      let uidData = this.getDevice(res.uid);
      if (uidData != null) {
        uidData.online = '0';
        uidData.wakeupTime = ''
      }
      this.setDeviceCount();
    })
    this.ws.addCallback('event', (res) => {
      if (res.type == "sensor") {
        let uidData = this.getDevice(res.uid);
        if (uidData != null) {
          if (uidData.model == "") {
            return;
          }
          let data;

          if (uidData.model == 'swLora' || uidData.model == 'dmWater' || uidData.model == 'dmMaster') {
            data = this.parseProfile.parseData2(uidData.model, res.data ? res.data : "");
          } else {
            data = this.parseProfile.parseData(uidData.model, res.data ? res.data : "");
          }
          // console.log("uid=" + res.uid + "res.data=" + res.data + ",parsed data=" + data);
          uidData.lastAlive = this.common.cGetDate('-') + " " + this.common.cGetTime(':');
          uidData.datas = JSON.parse(JSON.stringify(data));
        }
      } else if (res.type == "status") {
        let statusUid = res.uid
        let status = {
          "signal": this.parseProfile.getParamsData(res.data, "signal"),//信号
          "battery": this.parseProfile.getParamsData(res.data, "battery") != undefined ? this.parseProfile.getParamsData(res.data, "battery") : '',//电量
          "charge": this.parseProfile.getParamsData(res.data, "charge"),//充电状态
        }
        let divices = this.deviceData
        divices.forEach((item, index) => {
          if (item.uid == statusUid) {
            this.deviceData[index].status = status
          }
        })
        // console.log("divices:", this.deviceData);
      }
    })
    //设备休眠时间调整
    this.ws.addCallback('enterSleep', (res) => {
      // console.log('enterSleep:', res);
      let uidData = this.getDevice(res.uid);
      if (uidData != null) {
        uidData.wakeupTime = "唤醒时间:" + res.wakeupTime //唤醒时间
      }
    })
  }
}
</script>

<style lang="scss">
$card-w: 290px; //卡片宽度
// @import "../../assets/iconfont/icon_fields.css";
// @import "../../assets/iconfont/icon_dot.css";
@import "../../assets/iconfont/icon_online.css"; //设备状态
@import "../../assets/iconfont/icon_batterys.css"; //电池状态
#liveData {
  .device_count {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    grid-column-gap: 20px;
  }

  .c-container-box {
    box-shadow: 0 0 10px 6px $border_color;
    border-radius: 20px;
    margin: 20px 0 0 0;

    .el-tab-pane {
      padding: 10px !important;
    }
  }

  .device_status {
    display: inline-block;
    width: 35px;
    padding-right: 2px;
    .title {
      font-size: 8px;
      height: 16px;
    }
  }
  // 重写element样式
  .el-dropdown {
    color: inherit !important;
  }

  .icon_sleep:before {
    color: #5ab7fc;
    font-size: 16px;
  }

  .icon_offline:before {
    color: #d81e06;
    font-size: 16px;
  }

  .icon_online:before {
    color: #5fce48;
    font-size: 16px;
  }
}
</style>
