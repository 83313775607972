<!-- 实时数据设备统计 -->
<template>
  <div class="device_count">
    <div v-for="(item,index) in data" :key="index" id="chartSum">
      <div class="chart-sum" @click="itemFilter(item.num)">
        <i class="iconfont icon_dot" :style="{color:item.color}"></i>
        <div class="count">
          <span :class="{on:currentIndex===item.num}">{{item.title}}</span>
          <span>{{item.value}}</span>
        </div>
        <i class="iconfont icon_dot" :style="{color:item.color}"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: {
        color: '#409EFF',
        title: '统计',
        value: '0'
      }
    }
  },
  data() {
    return {
      currentIndex: 0
    }
  },
  methods: {
    itemFilter(item) {    //点击切换设备状态
      this.$emit('itemFilter', item)
      this.currentIndex = item
    }
  }
}
</script>

<style lang="scss">
@import "../assets/iconfont/icon_dot.css";

.device_count {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  grid-column-gap: 20px;
}

#chartSum {
  flex-grow: 1;
  flex: 1;

  .on {
    color: #000000 !important;
  }

  .chart-sum:hover {
    margin-top: -10px;
    transition: all 0.2s linear;
  }

  .chart-sum {
    min-width: 200px;
    max-height: 88px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $color_white;
    border: 1px solid $border_color;
    border-radius: 50px;
    box-shadow: 0 0 10px 6px $border_color;
    box-sizing: border-box;
    padding: 15px 25px;
    transition: all 0.2s linear;

    .count {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin: 0 20px;

      span {
        padding: 4px 0;

        &:first-child {
          font-size: 12px;
          color: lighten($color: $color_main, $amount: 30%);
        }

        &:last-child {
          font-size: 20px;
          font-weight: bold;
          color: lighten($color: $color_main, $amount: 10%);
        }
      }
    }

    i {
      color: $color_theme;

      &:last-child::before {
        font-size: 40px;
      }
    }
  }
}
</style>
